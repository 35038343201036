import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import BookingForm from '../../components/booking-form';
import Footer from '../../components/footer';
import Layout from '../../components/layout';
import ImageMapper from 'react-image-mapper';
import NavSection from '../../components/nav-section';

export class CafeArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moveMsg: '',
    };
  }

  load() {
    this.setState({ msg: 'Interact with image !' });
  }

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');

    const footer = get(this, 'props.data.allContentfulFooter.edges')

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    return (
      <Layout color="lightBrown">
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="The Loggia" />
        <div className="container">
          <div className="gallery-header">
            <h1>Visit the Palacio</h1>
            <h3>The Loggia</h3>
          </div>
        </div>
        <section className="container">
          <div className="floor-map">
            <div className="mini-map">
              <Link to="/venues" title="Back to Map">
                <img src="/images/cafe-mini.png" alt="" />
              </Link>
            </div>
            <div className="map">
              <ImageMapper
                src="/images/cafe-map.png"
                // map={CAFE_AREA}
                width={550}
                onLoad={() => this.load()}
              />
            </div>
          </div>
        </section>
        <section className="container">
          <div className="venue-whole text-center mb80">
            <div className="inclusions mt-5 mb-4">
              <h4 className="font-gtaM">SELECT A FLOOR TO VIEW</h4>
            </div>
            <a href="#bookingForm">
              <button className="btn">book this floor</button>
            </a>
          </div>
        </section>
        <BookingForm floor="the-loggia" bookingForm={bookingForm}/>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default CafeArea;

export const pageQuery = graphql`
  query CafeAreaQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
